import { localize } from 'vee-validate'

const mutations = {
	setLocale (state, locale) {
		state.locale = locale
		this.$i18n.locale = locale
		this.$i18n.setLocaleCookie(locale)
		localize(locale)
	},
	setState (state, data) {
		state[data.key] = data.value
	},
	setUser (state, user) {
		state.user = user

		if (user && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setUser({
					id: user.id,
					name: user.name
				})
			})
		}
	},
	setLocation (state, location) {
		state.location = location

		if (location && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('location', location)
			})
		}
	},
	setSelectedStore (state, store) {
		state.selectedStore = store

		if (store && typeof this.$sentry.configureScope === 'function') {
			this.$sentry.configureScope((scope) => {
				scope.setExtra('store', {
					id: store.id,
					name: store.name
				})
			})
		}
	},
	resetCart (state) {
		state.cart = {
			store: null,
			items: [],
			price: {
				subtotal: 0,
				tax: 0,
				inclusiveTaxTotal: 0,
				exclusiveTaxTotal: 0,
				taxes: [],
				discount: 0,
				discounts: [],
				total: 0,
				totalQuantity: 0
			}
		}
	}
}

export default mutations
