export default function ({ store }) {
	return fetch('https://ip2c.org/s').then(response => response.text()).then((response) => {
		const countryArr = response.split(';')

		if (countryArr[0] === '1') {
			store.commit('setState', {
				key: 'country',
				value: {
					name: countryArr[3],
					iso2: countryArr[1],
					iso3: countryArr[2]
				}
			})
		}
	}).catch(console.error)
}
