<template>
	<div
		class="container-fluid vh-100 d-flex justify-content-center align-items-center pb-3"
		style="flex-direction: column; padding-top: 80px"
	>
		<img
			:src="`/images/${error.statusCode === 404 ? 'not-found' : 'no-result'}.svg`"
			height="300px"
			alt="404"
		>
		<h1>
			<span>{{ error.statusCode }} -</span>
			<span v-if="error.statusCode === 404">
				{{ $t('pageNotFound') }}
			</span>
			<span v-else>{{ $t('toastMessage.somethingWentWrong') }}</span>
		</h1>
		<nuxt-link to="/">
			{{ $t('home page') | capitalize }}
		</nuxt-link>
	</div>
</template>

<script>
	export default {
		props: {
			error: {
				type: Object,
				default: null
			}
		}
	}
</script>
