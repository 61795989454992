export default function ({ $axios, store }) {
	$axios.onRequest((config) => {
		if (config.headers.common) {
			config.headers.common['Accept-Language'] = store.state.locale
		}
	})
	$axios.onError((err) => {
		console.error(err.request.method, err.request.path, err.response.data)

		if (err.response.status === 401 && err.response.config.url !== '/api/auth/login') {
			store.dispatch('logout').then(() => {
				if (process.client) {
					window.location.reload()
				}
			})
		}
	})
}
