const state = () => ({
	locale: 'en',
	authComponent: null,
	merchant: null,
	user: null,
	referralCode: null,
	location: null,
	nearbyStores: [],
	selectedStore: null,
	categories: {
		data: [],
		pagination: null
	},
	selectedItem: null,
	appliedCoupon: null,
	cart: {
		store: null,
		items: [],
		price: {
			subtotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: [],
			discount: 0,
			discounts: [],
			total: 0,
			totalQuantity: 0
		}
	},
	selectedOrderType: null,
	deliverySlot: null,
	deliveryAddress: null,
	paymentMethod: null
})

export default state
