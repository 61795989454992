import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _579141d9 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4f7e896d = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _12a303ec = () => interopDefault(import('../pages/items/index.vue' /* webpackChunkName: "pages/items/index" */))
const _99b545ae = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _e5282d44 = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _c2602d24 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _7869b955 = () => interopDefault(import('../pages/help/about-us.vue' /* webpackChunkName: "pages/help/about-us" */))
const _d4cacb22 = () => interopDefault(import('../pages/help/faq.vue' /* webpackChunkName: "pages/help/faq" */))
const _59a9683b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _10ec62a4 = () => interopDefault(import('../pages/items/_slug.vue' /* webpackChunkName: "pages/items/_slug" */))
const _50f13a5d = () => interopDefault(import('../pages/legal/_slug.vue' /* webpackChunkName: "pages/legal/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _579141d9,
    name: "account"
  }, {
    path: "/checkout",
    component: _4f7e896d,
    name: "checkout"
  }, {
    path: "/items",
    component: _12a303ec,
    name: "items"
  }, {
    path: "/account/addresses",
    component: _99b545ae,
    name: "account-addresses"
  }, {
    path: "/account/favorites",
    component: _e5282d44,
    name: "account-favorites"
  }, {
    path: "/account/orders",
    component: _c2602d24,
    name: "account-orders"
  }, {
    path: "/help/about-us",
    component: _7869b955,
    name: "help-about-us"
  }, {
    path: "/help/faq",
    component: _d4cacb22,
    name: "help-faq"
  }, {
    path: "/",
    component: _59a9683b,
    name: "index"
  }, {
    path: "/items/:slug",
    component: _10ec62a4,
    name: "items-slug"
  }, {
    path: "/legal/:slug?",
    component: _50f13a5d,
    name: "legal-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
