export default function ({ $axios, store, error }) {
	if (process.server) {
		return $axios.$get('/api/guest/settings').then((response) => {
			if (response.code === 200) {
				store.commit('setState', {
					key: 'merchant',
					value: response.data
				})
			}

			if (!store.state.location && response.data.settings.general.default_user_location &&
				Object.values(response.data.settings.general.default_user_location).filter(Number).length === 2) {
				store.commit('setLocation', {
					line: response.data.settings.general.default_user_location.name,
					coordinates: [
						response.data.settings.general.default_user_location.lng,
						response.data.settings.general.default_user_location.lat
					]
				})
			}
		}).catch((err) => {
			if (err.response) {
				console.error(err.request.method, err.request.path, err.response.data)
			}

			return error({ statusCode: 404, message: 'Merchant not found!' })
		})
	}
}
