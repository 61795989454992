<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<b-form-select v-model="locale" :options="options" />
</template>

<script>
	export default {
		data () {
			return {
				options: [
					{
						text: 'English',
						value: 'en'
					},
					{
						text: 'Arabic',
						value: 'ar'
					},
					{
						text: 'French',
						value: 'fr'
					}
				]
			}
		},
		computed: {
			locale: {
				get () {
					return this.$store.state.locale
				},
				set (value) {
					this.$store.commit('setLocale', value)
				}
			}
		}
	}
</script>
