<template>
	<b-modal
		v-if="selectedItem"
		id="attributes"
		:visible="show"
		:title="variationIndex !== -1 ? selectedItem.variations[variationIndex].name : selectedItem.name"
		centered
		scrollable
		@shown="init"
		@hidden="reset"
	>
		<b-form-group
			v-for="(attribute, i) in selectedItem.item_attributes"
			:key="`attribute-${i}`"
			:label="attribute.name"
			label-class="text-capitalize"
		>
			<b-form-radio-group
				v-model="attributes[attribute.slug]"
				:name="`attribute-${i}`"
			>
				<b-form-radio
					v-for="(option, j) in attribute.options"
					:key="`attribute-${option.slug}-${j}`"
					:value="JSON.stringify({
						label: option.label,
						slug: attribute.slug,
						value: option.value
					})"
					@change.native="checkAttributes"
				>
					<p class="mb-0">
						{{ option.label }}
					</p>
				</b-form-radio>
			</b-form-radio-group>
		</b-form-group>
		<template #modal-footer="{ cancel }">
			<div class="d-flex justify-content-between align-items-center w-100">
				<h5 class="text-capitalize m-0">
					{{ $t('total') }}: {{ toCurrency((variationIndex !== -1 ? selectedItem.variations[variationIndex].price : variation.price))
					}}
				</h5>
				<div>
					<b-button variant="secondary" class="text-capitalize" size="sm" @click="cancel()">
						{{ $t('cancel') }}
					</b-button>
					<b-button
						variant="success"
						class="text-capitalize"
						:disabled="variationIndex === -1"
						size="sm"
						@click="applyAttributes"
					>
						{{ $t('select item') }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				selectedAttributes: [],
				attributes: {}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			},
			variation () {
				return this.selectedItem ? this.selectedItem.variations[this.selectedItem.variation_index] : this.item ? this.item.variations[this.item.variation_index] : null
			},
			variationIndex () {
				return this.selectedItem.variations.findIndex((v) => {
					return v.item_attributes.every(a => this.selectedAttributes.findIndex((sa) => {
						return sa.slug === a.slug && sa.value === a.value
					}) !== -1)
				})
			}
		},
		methods: {
			init () {
				this.selectedAttributes = [...this.selectedItem.selected_attributes]

				this.selectedAttributes.forEach((attribute) => {
					this.attributes[attribute.slug] = JSON.stringify(attribute)
				})
			},
			checkAttributes ($event) {
				const attribute = JSON.parse($event.target.value)
				const index = this.selectedAttributes.findIndex(a => a.slug === attribute.slug)

				if (index !== -1) {
					this.selectedAttributes.splice(index, 1)
				}

				this.selectedAttributes.push(attribute)
			},
			applyAttributes () {
				this.selectedItem.selected_attributes = [...this.selectedAttributes]
				this.selectedItem.variation_index = this.variationIndex
				this.reset()
			},
			reset () {
				this.selectedItem = null
				this.selectedAttributes = []
				this.attributes = {}
				this.$emit('close')
			}
		}
	}
</script>

<style lang="scss">
	#attributes {
		.custom-control-label {
			line-height: 1.2
		}
	}
</style>
