const getters = {
	navigationProp: state => (key) => {
		return state.navigationProps[key]
	},
	getDiscountQtyArr: state => (prerequisite, type) => {
		return Object.keys(prerequisite).reduce((arr, key) => {
			if (prerequisite[key].qty) {
				if (type === 'item') {
					const cartItem = state.cart.items.find(i => i.variations[i.variation_index].id === +key)

					arr[0] += cartItem.variations[cartItem.variation_index].quantity
				} else {
					arr[0] += state.cart.items.reduce((qty, i) => {
						if (i.variations[i.variation_index].category_id === +key) {
							qty += i.variations[i.variation_index].quantity - i.variations[i.variation_index].discounted_quantity
						}

						return qty
					}, 0)
				}

				arr[1] += prerequisite[key].qty['>=']
			} else {
				arr[0] = 1
				arr[1] = 1
			}

			return arr
		}, [0, 0])
	}
}

export default getters
