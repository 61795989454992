export default (ctx, inject) => {
  const transformNumber = (value) => {
    const numberFormat = new Intl.NumberFormat(ctx.store.state.locale, {
      style: 'currency',
      currency: ctx.store.state.merchant.stores[0].currency_code
    }).resolvedOptions()

    return parseFloat(value.toFixed(numberFormat.maximumFractionDigits))
  }

  inject('transformNumber', transformNumber)
}
