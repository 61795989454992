import { setInteractionMode, extend, configure } from 'vee-validate'
// eslint-disable-next-line
import { required, email, min, max, alpha_num, alpha_spaces, length } from 'vee-validate/dist/rules'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import { PhoneNumberUtil } from 'google-libphonenumber'

export default ({ app, store }) => {
	configure({
		defaultMessage: (field, values) => {
			return app.i18n.t(`validation.${values._rule_}`, values)
		}
	})

	setInteractionMode('eager')

	extend('required', required)
	extend('email', email)
	extend('min', min)
	extend('max', max)
	extend('alpha_num', alpha_num)
	extend('alpha_spaces', alpha_spaces)
	extend('length', length)
	extend('equals', {
		validate: (value, { other }) => value === other,
		params: [{ name: 'other', isTarget: true }]
	})
	extend('password', {
		validate: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()])(?=.{8,})/.test(value),
		message: 'Please enter a strong password'
	})
	extend('mobile', {
		validate: (value) => {
			if ((value.number?.length || 0) < 4) {
				return false
			} else {
				const phoneUtil = PhoneNumberUtil.getInstance()

				return phoneUtil.isValidNumberForRegion(phoneUtil.parse(value.number, value.country.code), value.country.code)
			}
		},
		params: ['code']
	})
	extend('mobileRequired', {
		validate: (value) => {
			if (value.number) {
				return true
			}
		}
	})
	extend('postalCode', {
		validate: value => postcodeValidatorExistsForCountry(store.state.merchant.settings.general.country_code) ? postcodeValidator(value, store.state.merchant.settings.general.country_code) : true,
		params: ['code']
	})
}
