const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['category'] = require('../middleware/category.js')
middleware['category'] = middleware['category'].default || middleware['category']

middleware['country'] = require('../middleware/country.js')
middleware['country'] = middleware['country'].default || middleware['country']

middleware['merchant'] = require('../middleware/merchant.js')
middleware['merchant'] = middleware['merchant'].default || middleware['merchant']

middleware['nearby-store'] = require('../middleware/nearby-store.js')
middleware['nearby-store'] = middleware['nearby-store'].default || middleware['nearby-store']

export default middleware
