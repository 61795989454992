<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<b-modal
		v-if="variation"
		id="modifiers"
		:visible="show"
		:title="variation.name"
		centered
		scrollable
		@show="init"
		@shown="selectRequiredModifiers"
		@hidden="reset"
	>
		<b-form-group
			v-for="(modifier, modifierIndex) in variation.modifiers"
			:key="modifierIndex"
			:label="modifier.name"
			label-class="text-capitalize"
		>
			<component
				:is="modifier.multi_choice ? 'b-form-checkbox-group' : 'b-form-radio-group'"
				:name="`${modifier.id}-${modifier.name.toLowerCase()}`"
			>
				<component
					:is="modifier.multi_choice ? 'b-form-checkbox' : 'b-form-radio'"
					v-for="(item, itemIndex) in modifier.items"
					:key="itemIndex"
					:disabled="validateModifier(variation, modifier, item)"
					:value="JSON.stringify({
						group_id: modifier.id,
						item_id: item.id,
						item_name: item.name,
						price: item.price,
						qty: 1,
						multi_choice: modifier.multi_choice
					})"
					@change.native="checkModifiers"
				>
					<p class="mb-0">
						{{ item.name }}
					</p>
					<small>
						{{ toCurrency(item.price) }}
					</small>
				</component>
			</component>
		</b-form-group>
		<template #modal-footer="{ cancel }">
			<div class="d-flex justify-content-between align-items-center w-100">
				<h5 class="text-capitalize m-0">
					{{ $t('total') }}: {{ toCurrency(variation.original_price + selectedModifiersPrice)
					}}
				</h5>
				<div>
					<b-button variant="secondary" class="text-capitalize" size="sm" @click="cancel()">
						{{ $t('cancel') }}
					</b-button>
					<b-button
						variant="success"
						class="text-capitalize"
						size="sm"
						@click="applyModifiers"
					>
						{{ $t('add item') }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				selectedModifiers: [],
				variations: [],
				requiredModifiers: []
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			cart () {
				return this.$store.state.cart
			},
			selectedItem: {
				get () {
					return this.$store.state.selectedItem
				},
				set (value) {
					this.$store.commit('setState', { key: 'selectedItem', value })
				}
			},
			variation () {
				return this.selectedItem ? this.selectedItem.variations[this.selectedItem.variation_index] : this.item ? this.item.variations[this.item.variation_index] : null
			},
			selectedModifiersPrice () {
				return this.selectedModifiers.reduce((sum, m) => {
					sum += m.price

					return sum
				}, 0)
			}
		},
		methods: {
			init () {
				this.variations = this.selectedItem.variations.map((v) => {
					v.modifiers.reduce((requiredModifiers, modifier) => {
						if (modifier.required) {
							requiredModifiers.push(`${modifier.id}-${modifier.name.toLowerCase()}`)
						}

						return requiredModifiers
					}, this.requiredModifiers)

					return {
						...v,
						quantity: v.quantity || 0
					}
				})
			},
			selectRequiredModifiers () {
				this.requiredModifiers.forEach((modifierName) => {
					const input = document.querySelector(`input[name="${modifierName}"]`)

					if (input && !input.checked) {
						input.click()
					}
				})
			},
			checkModifiers ($event) {
				const modifier = JSON.parse($event.target.value)

				if ($event.target.type === 'radio') {
					const index = this.selectedModifiers.findIndex(m => m.group_id === modifier.group_id)

					if (index !== -1) {
						this.selectedModifiers.splice(index, 1)
					}
				}

				if ($event.target.checked) {
					this.selectedModifiers.push(modifier)
				} else {
					this.selectedModifiers.splice(this.selectedModifiers.findIndex((m) => {
						return m.group_id === modifier.group_id && m.item_id === modifier.item_id
					}), 1)
				}
			},
			validateModifier (variation, modifier, mVariation) {
				const maxModifiersSelectable = (
					variation.max_selectable
						? variation.max_selectable
						: null
				) || null
				const maxOptionsSelectable = modifier.multi_choice ? modifier.max_selectable : 0
				const uniqModifiers = this.selectedModifiers
					? variation.modifiers.reduce((modifiers) => {
						for (const smk in this.selectedModifiers) {
							const sm = this.selectedModifiers[smk]

							if (sm) {
								if (modifiers[sm.group_id]) {
									const index = modifiers[sm.group_id].findIndex(element => element === sm.item_id)

									if (index === -1) {
										modifiers[sm.group_id].push(sm.item_id)
									}
								} else {
									modifiers[sm.group_id] = [sm.item_id]
								}
							}
						}

						return modifiers
					}, {})
					: {}

				return mVariation.track_inventory && mVariation.stock < 1
					? true
					: ((!uniqModifiers[modifier.id] && maxModifiersSelectable > 0 && Object.keys(uniqModifiers).length >= maxModifiersSelectable) ||
						(uniqModifiers[modifier.id] && maxOptionsSelectable > 0 && uniqModifiers[modifier.id].length >= maxOptionsSelectable && !uniqModifiers[modifier.id].includes(mVariation.id))
					)
			},
			applyModifiers () {
				this.variation.selected_modifiers = this.selectedModifiers
				this.$root.$emit('apply-modifiers', this.selectedItem)
				this.reset()
			},
			reset () {
				this.selectedItem = null
				this.selectedModifiers = []
				this.$emit('close')
			}
		}
	}
</script>

<style lang="scss">
	#modifiers {
		.custom-control-label {
			line-height: 1.2
		}
	}
</style>
